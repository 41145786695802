import styled from '@emotion/styled'
import Theme from '@styles/theme'
import { StylesConfig, Theme as ReactSelectTheme } from 'react-select'
import { selectInputClasses } from './classes'

const selectFontStyle = {
	fontSize: 14,
	lineHeight: '24px',
	fontWeight: 400
}

export const customSelectStyle = (hasError: boolean): StylesConfig => {
	// WTF is cette merde ?
	return {
		control: (basestyle, state) => ({
			...basestyle,
			backgroundColor: Theme.colors.blue2,
			minHeight: 50,
			padding: 6,
			borderRadius: 10,
			border: `1px solid`,
			borderColor: hasError ? Theme.colors.red() : state.menuIsOpen ? Theme.colors.white() : Theme.colors.grey2,
			boxShadow: 'none',
			outline: 'none',
			':hover': {
				borderColor: hasError
					? Theme.colors.red()
					: state.menuIsOpen
					? Theme.colors.white()
					: Theme.colors.grey2
			},
			caretColor: 'transparent',
			color: hasError ? Theme.colors.red() : state.menuIsOpen ? Theme.colors.white() : Theme.colors.grey2,
			'[class*="placeholder"]': {
				color: hasError ? Theme.colors.red() : 'inherit'
			}
		}),
		indicatorSeparator: () => ({
			display: 'none'
		}),
		menu: (basestyle) => ({
			...basestyle,
			borderRadius: 10,
			padding: 0,
			overflow: 'hidden'
		}),
		menuList: (basestyle) => ({
			...basestyle,
			padding: `0 ${Theme.spacing(3)}`,
			maxHeight: 200
		}),
		option: (basestyle, state) => ({
			...basestyle,
			...selectFontStyle,
			display: 'flex',
			alignItems: 'center',
			cursor: 'pointer',
			maxHeight: 34,
			borderRadius: 10,
			paddingLeft: Theme.spacing(2),
			color: state.isSelected ? Theme.colors.white() : Theme.colors.grey2,
			backgroundColor: state.isSelected ? Theme.colors.grey1 : 'transparent',
			':hover': {
				backgroundColor: Theme.colors.grey1,
				color: Theme.colors.white()
			},
			':first-of-type': {
				marginTop: Theme.spacing(2)
			},
			':last-of-type': {
				marginBottom: Theme.spacing(2)
			}
		}),
		singleValue: (basestyle) => ({
			...basestyle,
			...selectFontStyle,
			color: Theme.colors.white()
		}),
		valueContainer: (basestyle) => ({
			...basestyle,
			display: 'flex',
			alignItems: 'center',
			padding: 0,
			marginLeft: 12,
			gap: 8,
			fontSize: '14px'
		}),
		multiValue: (basestyle) => ({
			...basestyle,
			...selectFontStyle,
			borderRadius: 6,
			backgroundColor: Theme.colors.grey1
		}),
		multiValueLabel: (basestyle) => ({
			...basestyle,
			paddingLeft: 8,
			color: Theme.colors.white(),
			fontWeight: 700
		}),
		multiValueRemove: (basestyle) => ({
			...basestyle,
			color: Theme.colors.white(),
			cursor: 'pointer',
			':hover': {
				backgroundColor: Theme.colors.grey1
			},
			svg: {
				width: 16,
				height: 16
			}
		}),
		indicatorsContainer: (basestyle) => ({
			...basestyle,
			cursor: 'pointer',
			maxHeight: 36
		}),
		noOptionsMessage: (basestyle) => ({
			...basestyle,
			display: 'none'
		})
	}
}

export const customSelectTheme = (theme: ReactSelectTheme) => {
	return {
		...theme,
		borderRadius: 10,
		colors: {
			...theme.colors,
			primary: Theme.colors.grey1,
			primary25: 'transparent',
			neutral20: Theme.colors.grey2,
			neutral0: Theme.colors.blue1
		}
	}
}

export const SelectWrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;

	.${selectInputClasses.label}  {
		margin-bottom: 20px;
	}
	.select-input-clear {
		padding-right: 1px;
	}

	&.error {
		color: ${Theme.colors.red()};
	}
`

export const DropdownIndicatorWrapper = styled.div<{ menuIsOpen: boolean }>`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 25px;
	width: 25px;
	border-radius: 8px;
	background-color: ${({ menuIsOpen }) => (menuIsOpen ? Theme.colors.white() : Theme.colors.grey1)};
	color: ${Theme.colors.white()};
	margin: ${Theme.spacing(1)};
	margin: 4px;
	cursor: pointer;
	& svg {
		fill: ${({ menuIsOpen }) => (menuIsOpen ? Theme.colors.blue1 : Theme.colors.white())};
		transform: ${({ menuIsOpen }) => (menuIsOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
	}
`

export const DeleteBtnWrap = styled.div`
	background: ${Theme.colors.red()};
	padding: 7.5px;
	border-radius: 8px;
	cursor: pointer;
	pointer-events: all;
	height: 25px;
	width: 25px;
`
