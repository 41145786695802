import Text from '@components/Micro/Text'
import React, { useImperativeHandle, useRef, useState } from 'react'
import ReactSelect, {
	DropdownIndicatorProps,
	Props as ReactSelectProps,
	ValueContainerProps,
	components,
	ClearIndicatorProps
} from 'react-select'
import Icon from '../../Icon'
import { customSelectStyle, customSelectTheme, DeleteBtnWrap, DropdownIndicatorWrapper, SelectWrapper } from './style'
import Theme from '@styles/theme'
import { selectInputClasses } from './classes'
import classNames from 'classnames'
import { SimpleGrid } from '@styles/Grid'

type SelectInputProps = ReactSelectProps & {
	label?: string
	onSelect: (result: SelectInputResult | string) => void
	innerRef?: React.RefObject<SelectInputHandle>
}

export type SelectInputResult = {
	objects: SelectInputOption[]
	array: string[]
}

export type SelectInputOption = {
	value: string
	label: string
}

export type SelectInputHandle = {
	setInputError: (error: string) => void
}

const DropdownIndicator: React.FC<DropdownIndicatorProps> = ({ selectProps }) => {
	const menuIsOpen = selectProps.menuIsOpen
	return (
		<DropdownIndicatorWrapper menuIsOpen={menuIsOpen} className={selectInputClasses.toggle}>
			<Icon name="chevron_down" width="11px" height="11px" />
		</DropdownIndicatorWrapper>
	)
}

const ValueContainer: React.FC<ValueContainerProps> = ({ ...props }) => {
	return (
		<components.ValueContainer {...props} className={selectInputClasses.valueContainer}>
			{props.children}
		</components.ValueContainer>
	)
}

const ClearIndicator: React.FC<ClearIndicatorProps> = (props) => {
	return (
		<components.ClearIndicator {...props} className={selectInputClasses.clear}>
			<DeleteBtnWrap>
				<Icon name={'trash'} width="100%" height="100%" fill={Theme.colors.white()} />
			</DeleteBtnWrap>
		</components.ClearIndicator>
	)
}

const SelectInput: React.FC<SelectInputProps> = ({
	label,
	closeMenuOnSelect,
	onSelect,
	components,
	placeholder,
	innerRef,
	...props
}) => {
	const [error, setError] = useState<string | null>(null)
	const customStyle = customSelectStyle(!!error)
	const inputRef: React.MutableRefObject<any> = useRef(null)

	const scrollToError = () =>
		inputRef.current.scrollIntoView({
			block: 'center',
			inline: 'center',
			behavior: 'smooth',
			alignToTop: true
		})

	useImperativeHandle<any, SelectInputHandle>(
		innerRef,
		(): SelectInputHandle => ({
			setInputError(error: string) {
				setError(error)
				scrollToError()
			}
		}),
		[]
	)

	const handleChange = (value: SelectInputOption | SelectInputOption[]) => {
		if (Array.isArray(value)) {
			onSelect({
				objects: value,
				array: value.map((i) => i.value)
			})
		} else {
			onSelect(value.value)
		}
		setError(null)
	}

	return (
		<SelectWrapper className={classNames(selectInputClasses.root, { error: !!error })} ref={inputRef}>
			{label && (
				<Text
					className={selectInputClasses.label}
					weight="bold"
					size="12px"
					color={error ? Theme.colors.red() : Theme.colors.white()}
				>
					{label}
				</Text>
			)}
			<SimpleGrid gap={Theme.spacing(1)}>
				<div>
					<ReactSelect
						{...props}
						closeMenuOnSelect={!!closeMenuOnSelect}
						styles={customStyle}
						theme={customSelectTheme}
						components={{ DropdownIndicator, ClearIndicator, ValueContainer, ...components }}
						onChange={handleChange}
						placeholder={placeholder}
					/>
				</div>
				{error && (
					<Text size="12px" color={Theme.colors.red()}>
						{error}
					</Text>
				)}
			</SimpleGrid>
		</SelectWrapper>
	)
}

export default SelectInput
